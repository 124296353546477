
import {
  faCoffee,
  faSpinner,
  faBroom,
  faEnvelope,
  faSearch,
  faAngleDoubleRight,
  faPlusCircle,
  faEllipsisV,
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalculator,
  faInfo,
  faChevronCircleRight,
  faDownload

} from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

export const fontAwesomeIconsLib = [
  fab,
  faBroom,
  faCoffee,
  faSpinner,
  faEnvelope,
  faSearch,
  faAngleDoubleRight,
  faMapMarkerAlt,
  faChevronLeft,
  faPlusCircle,
  faEllipsisV,
  faMapMarkerAlt,
  faChevronRight,
  faCalculator,
  faInfo,
  faChevronCircleRight,
  faDownload
]
