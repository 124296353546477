import React from "react"
import {
  Container,
  Row,
  Col
} from "reactstrap"

import "../styles/footer.css"
import n12 from "../images/n12-logo.svg"

function Footer (){
  return(
    <Container>
      <Row className="cp_foot justify-content-between mt-5">
        <Col sm={12} md={6} className="">
          <a href="https://www.n12.mx" className="" target="_blank" rel="noopener noreferrer">

            <h5 className="website">www.n12.mx</h5>

          </a>
        </Col>
        <Col sm={12} md={6} className="ct2_foot">

          <h5 className="">N12 Estudio 2020®</h5>

        </Col>
      </Row>

      <Row className="cp_foot mt-5">
        <p className="mx-3">
          Este material ha sido elaborado por N12 Estudio® para uso exclusivo de la marca aquí mencionada. Queda estrictamente a consideración de la marca propietaria la distribución y/o reproducción de la información aquí presentada.
        </p>
      </Row>

      <Row className="justify-content-center text-center my-5">
        <a href="https://www.n12.mx" target="_blank" rel="noopener noreferrer">
          <img src={n12} alt="" className="custom_image_size"/>
        </a>
      </Row>
    </Container>
  )
}

export default Footer
