import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import {fontAwesomeIconsLib} from '../config/fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'

import "bootstrap/dist/css/bootstrap.min.css"

import { Navb, Footer } from "./"
import "../styles/layout.css"


library.add(...fontAwesomeIconsLib)

require('typeface-nunito')
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navb siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
