import React from "react"
import {Navbar,
  Nav,
  NavItem,
  NavLink} from "reactstrap"
import {Link} from 'gatsby';
import "../styles/navb.css"

const routeLinks =[
  {
    to:'#home/',
    name:'Inicio'
  },
  {
    to:'#guides/',
    name:'Guías'
  },
  {
    to:'#resources/',
    name:'Recursos'
  }
]

function Navb () {

      return (
          <Navbar fixed="top" expand="sm">
            <Nav className="sticky-top navb-margin" navbar>
              {routeLinks.map(({to,name,only_sm},i)=><NavItem key={i}>
                     <NavLink className={`navb-text mx-1 ${only_sm?'d-md-none':''}`} tag={Link} to={to}>
                       <h3 className="">{name}</h3>

                     </NavLink>
                   </NavItem>)}
            </Nav>
      </Navbar>
          )
      }

export default Navb
